<template>
  <div class="box" ref="box">
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
    <div class="boxWarp">
      
      <div class="tiKuTitle" v-if="questionBank_info.subjectNodeName">{{questionBank_info.subjectNodeName}}</div>
      <div class="tiKuTitle" v-else>{{examInfo.testPaperName}}</div>
      <div class="oneBox">
        <div class="item0">
          <div class="item_title">
            <div class="leftIconStyle t1" style="margin-left:21px">01</div> 
            <div class="leftIconStyle" style="margin-left:12px">|</div>
            <div class="leftIconStyle t" style="margin-left:12px">答题预览</div>
          </div>
          <div class="crileBox">
            <el-progress type="circle" style="color:#0092FF" :show-text="false" text-color="#0092FF" :stroke-width="16" :percentage="huanNum"></el-progress>
            <div class="text">{{huanText}}</div>
          </div>
          <div class="finshBox" v-if="num == 1">正确率</div>
          <div class="finshBox" v-if="num == 2">客观题得分</div>
          <div class="question_situation">
            <div>
              <div class="fontStyle fontColor">{{questionBank_info.completeNumber}}/{{questionBank_info.countNumber}}</div>
              <div class="bottomText">答题数</div>
            </div>
            <div class="lineBox mar"></div>
            <div class="mar"> 
              <div class="fontStyle fontColor">{{questionBank_info.rightNumber}}</div>
              <div class="bottomText">答对数</div>
            </div>
            <div class="lineBox mar"></div>
            <div class="mar">
              <div class="fontStyle errorColor">{{questionBank_info.errorNumber}}</div>
              <div class="bottomText">答错数</div>
            </div>
            <div class="lineBox mar"></div>
            <div class="mar">
              <div class="fontStyle fontColor" v-if="questionBank_info.sumDuration">{{formatSeconds(questionBank_info.sumDuration)}}</div>
              <div class="bottomText">用时</div>
            </div>
          </div>
        </div>
      </div>
      <div class="twoBox">
        <div>
          <div class="topbox" style="height:30px;"><span class="da">{{questionBank_info.totalMaxAccuracy}}</span><span class="xiao">%</span></div>
          <div class="bottomTextBox" style="height:24px;">全站最高正确率</div>
        </div>
        <div>
          <div class="topbox" style="height:30px;"><span class="da">{{questionBank_info.totalAveAccuracy}}</span><span class="xiao">%</span></div>
          <div class="bottomTextBox" style="height:24px;">全站平均正确率</div>
        </div>
        <div>
          <div class="topbox" style="height:30px;"><span class="da">{{questionBank_info.defeatAccuracy}}</span><span class="xiao">%</span></div>
          <div class="bottomTextBox" style="height:24px;">已击败考生</div>
        </div>
      </div>
      <div class="oneBox">
        <div class="item0">
          <div class="item_title">
            <div class="leftIconStyle t1" style="margin-left:21px">02</div> 
            <div class="leftIconStyle" style="margin-left:12px">|</div>
            <div class="leftIconStyle t" style="margin-left:12px">答题卡</div>
          </div>
          <div class="cardContent">
            <div class="cardTop">
              <div class="childBox">
                <div class="card_Box_question colorRight"></div>
                <div class="card_text">正确</div>
              </div>
              <div class="childBox">
                <div class="card_Box_question colorError" style="margin-left:15px;"></div>
                <div class="card_text">错误</div>
              </div>
              <div class="childBox">
                <div class="card_Box_question colorWei" style="margin-left:15px;"></div>
                <div class="card_text">未答</div>
              </div>
            </div>
            <div class="questionCardBox">
              
              <template v-for="item in answerCardData">
                <div style="width:100%;height:25px;"></div>
                <span class="title">{{ item.name }}</span>
                <div class="content">
                  <template v-for="(val,index) in item.children">
                    <div :class="setCardBoxStyle(val)"   v-if="!val.subjectList.length">
                      {{ (index + 1) }}
                     
                    </div>
                    <template v-else v-for="(val1,index1) in val.subjectList">
                      <div :class="setCardBoxStyle(val1)" >
                        {{ (index + 1) +'.'+ (index1 + 1) }}
                       
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </div>
          
        </div>
      </div>
      <div class="oneBox">
        <div class="item0">
          <div class="item_title">
            <div class="leftIconStyle t1" style="margin-left:21px">03</div> 
            <div class="leftIconStyle" style="margin-left:12px">|</div>
            <div class="leftIconStyle t" style="margin-left:12px">成绩曲线</div>
          </div>
          <div class="chartsBox">
            <div id="echarts" class="pieBox"></div>
          </div>
          <div class="bottom_btn">
            <el-button type="primary" @click="analysis(2)">全部解析</el-button>
            <el-button style="margin-left:30px" type="primary" @click="analysis(1)">错题解析</el-button>
            <el-button style="margin-left:30px" type="warning" @click="again()">再次练习</el-button>
            <el-button style="margin-left:30px" type="primary" @click="goHome()">返回首页</el-button>
          </div>
          
        </div>
      </div>
      
    </div>
    <t_footer></t_footer>
  </div>
</template>
  
  <script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'  
import { assignment_exercise, analysis_list, analysis_new, subject_analysisList, assignment_paper ,analysisList_new} from "@/api/exam";  
export default {
  name: "tikuReport",
  components: {
    
    t_header,
    t_footer,
  },
  head() {
    return {
      title: "题库报告 - 会计通"
    };
  },
  data() {
    return {
      isUserInfo: false,
      examInfo:{
        testPaperName:""
      },
      tabIndex:1,
      huanText:"",
      huanNum:0,
      listData: [],
      nodeType: 0,
      nodeId: '',
      testPaperId: '',
      num: 1,
      questionBank_info:{
        subjectNodeName:"",
        completeNumber:"",
        countNumber:"",
        rightNumber:"",
        errorNumber:"",
        totalMaxAccuracy:"",
        totalAveAccuracy:"",
        defeatAccuracy:"",
        sumDuration:"",

      },
      answerCardData:[],
      typeList: [
        {type: '1', name: '单选题'},
        {type: '2', name: '多项选择题'},
        {type: '3', name: '判断题'},
        {type: '4', name: '不定项选择题'},
        {type: '5', name: '计算分析题'},
        {type: '6', name: '综合题'},
        {type: '7', name: '简答题'},
        {type: '8', name: '计算回答题'},
        {type: '9', name: '案例分析题'},
        {type: '10', name: '计算题'},
        {type: '11', name: '综合分析题'}
      ],
    };
  },
  watch: {},

  created() {},
  mounted() {
    var that = this;
    var pageQuery = that.$route.query;
    var testReportInfo = localStorage.getItem("testReport");
    
    if (testReportInfo) {
      testReportInfo = JSON.parse(testReportInfo)
      that.examInfo = testReportInfo.examInfo
      that.num = testReportInfo.num
      that.twoIindex = testReportInfo.twoIindex

      that.tabIndex = testReportInfo.tabIndex
      that.firstId = testReportInfo.firstId
      that.secondId = testReportInfo.secondId
    }
    console.log("this.num",this.num);
    if (this.num == 1) {
       this.nodeId = pageQuery.nodeId
       this.nodeType = pageQuery.nodeType;
      // this.nodeId = "2";
      // this.nodeType = "1";
      this.assignment_exercise();
      this.analysis_new();
    } else {
      // this.nodeType=2
      this.testPaperId = pageQuery.testPaperId
      // this.isTrue=num
      this.assignment_paper();
      //this.subject_analysisList()
      this.subject_analysisList_new();
    }
  },
  methods: {
    // 父传子
    getTodos(e) {
      this.isUserInfo = e
    },
    formatSeconds(value) {
      let res = parseInt(value);
      let h = Math.floor(res / 3600) < 10 ? '0' + Math.floor(res / 3600) : Math.floor(res / 3600);
      let m = Math.floor(res / 60 % 60) < 10 ? '0' + Math.floor(res / 60 % 60) : Math.floor(res / 60 % 60);
      let s = Math.floor(res % 60) < 10 ? '0' + Math.floor(res % 60) : Math.floor(res % 60);

      let str = '';
      str += `${h}:`;
      str += `${m}:`;
      str += s;
      return str;
    },
    setCardBoxStyle(item){
      
      if(item.isRight == '1'){
        return 'card_Box_question colorRight';
      }else if(item.isRight == '2'){
        return 'card_Box_question colorError';
      }else{

        return 'card_Box_question colorWei';  //rgb(151, 156, 164)
      }
    },
    again() {
      var twoIindex = ''
      if (this.tabIndex == '6' || this.tabIndex == '8') {
        twoIindex = this.tabTwoIindex
      } else {
        twoIindex = ''
      }
     
      var examTextInfo = localStorage.getItem("tiku_examText");
      if (examTextInfo) {
        examTextInfo = JSON.parse(examTextInfo);
        examTextInfo.value = 2;
        localStorage.setItem('tiku_examText',JSON.stringify(examTextInfo));
      }
      if (this.tabIndex == 1 || this.tabIndex == 2) {
       
        this.$router.push({
          path: '/tikuDetail',
          query: {
            // chapterListItem: JSON.stringify(this.examInfo),
            // num:num,
            tabIndex: this.tabIndex,
            twoIindex: twoIindex,
            firstId: this.firstId,
            secondId: this.secondId
          }
        })

      } else if (this.tabIndex == 3 || this.tabIndex == 4 || this.tabIndex == 5 ) {
        this.$router.push({
          path: '/tikuDetail',
          query: {
            // chapterListItem: JSON.stringify(this.examInfo),
            // num:num,
            tabIndex: this.tabIndex,
            // value: 2,
            twoIindex: twoIindex,
            firstId: this.firstId,
            secondId: this.secondId,
            testPaperId:this.testPaperId,
          }
        })
      }else if(this.tabIndex == 9){
        this.$router.push({
          path: '/mnjktiku',
          query: {
            // chapterListItem: JSON.stringify(this.examInfo),
            // num:num,
            tabIndex: this.tabIndex,
            // value: 2,
            twoIindex: twoIindex,
            firstId: this.firstId,
            secondId: this.secondId,
            testPaperId:this.testPaperId,
            type:this.secondId
          }
        })

      }

    },
    // echarts
    drawLine() {
      var that = this
      var myChart = that.$echarts.init(document.getElementById('echarts'))
      // 基于准备好的dom，初始化echarts实例
      // 绘制图表
      var option = {

        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
      
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: that.echartsHangList,
            minInterval: 1,
            splitNumber: 5,
            axisLine: { lineStyle: {color: '#676F7A'}}
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: '100',
            axisLine: { lineStyle: {color: '#676F7A'}},
            splitLine: {
                show: true,
                lineStyle:{
                    type:'dashed'
                }
            }
          },
          
        ],
        series: [

          {
            name: '',
            type: 'line',
            stack: 'Total',
            label: {
              show: true,
              position: 'top'
            },
            smooth: 0.5,
            symbol: 'circle',
            symbolSize: 5,
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: '#0092FF'
            },
            areaStyle: {//区域填充渐变颜色
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 1,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: 'rgba(0,196,255,0)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(0,146,255,1.00)' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    }
            },
            data: that.echartsShuList
          }
        ]
      };
      myChart.setOption(option);
    },
    // 父传子
    getTodos(e) {
      this.isUserInfo = e
    },
    // 去解析
    analysis(num) {
      this.$router.push({
        path: '/tikuAnalysis',
        query: {
          tabIndex: this.tabIndex,
          twoIindex: this.twoIindex,
          isError: num,
          num: this.num,
          nodeType: this.nodeType,
          nodeId: this.nodeId,
          testPaperId: this.testPaperId
        }
      })
    },
    goHome() {
      this.$router.push({
          path: '/examcenter',
          query: {
           
            firstId: this.firstId,
            secondId: this.secondId
          }
        })
    },
    // 章节和专项练习
    assignment_exercise() {
      var obj = {
        nodeId: this.nodeId,
        nodeType: this.nodeType,
        terrace:'2'
      }
      assignment_exercise(obj).then(res => {
        if (res.data.code == 0) {
          this.questionBank_info = res.data.data;
          this.format_one();
          var arr = []
          var arr2 = []
          for (var i in this.questionBank_info.scoreList) {
            arr.push(this.questionBank_info.scoreList[i].accuracy)
            arr2.push(Number(i) + 1)
          }
          this.echartsHangList = arr2
          this.echartsShuList = arr
          this.$nextTick(() => {
            // this.init();
            this.drawLine()
          });
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 章节练习的解析 -- new
    analysis_new() {
     
      var that=this;
      var obj = {
        nodeId: this.nodeId,
        nodeType: this.nodeType,
        type: "2",
      }
      analysis_new(obj).then(res => {
        if (res.data.code == 0) {
          
          this.answerCardData = [];
          
          let questionData = res.data.data;
          this.typeList.map(v => {
            if(questionData) {
              let paper = questionData.filter(val => val.classify === v.type);
              if (paper.length) {
                this.answerCardData.push({name: v.name, children: paper});
              }
            }
          })
         
         
          

        } else {
          
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 历年/模拟/考前试卷-交卷or答题报告
    assignment_paper() {
      var obj = {
        testPaperId: this.testPaperId,
        terrace:'2'
      }
      assignment_paper(obj).then(res => {
        if (res.data.code == 0) {
          this.questionBank_info = res.data.data;
          this.format_one();
          var arr = []
          var arr2 = []
          for (var i in this.questionBank_info.scoreList) {
            arr.push(this.questionBank_info.scoreList[i].score)
            arr2.push(Number(i) + 1)
          }
          this.echartsHangList = arr2
          this.echartsShuList = arr
          this.$nextTick(() => {
            // this.init();
            this.drawLine()
          });
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 历年/模拟/考前试卷-题解析列表 - new
    subject_analysisList_new() {
     
      var that=this;
      var obj = {
        testPaperId: this.testPaperId,
        type:"2",
      }
      analysisList_new(obj).then(res => {
        if (res.data.code == 0) {
          this.analysisObj = res.data.data
          let questionData = res.data.data;
          this.typeList.map(v => {
            if(questionData) {
              let paper = questionData.filter(val => val.classify === v.type);
              if (paper.length) {
                this.answerCardData.push({name: v.name, children: paper});
              }
            }
          })
          

        } else {
         
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 历年/模拟/考前试卷-题解析列表
    // 进度条的文字内容显示
    format_one() {
      if(this.num == 1){
        this.huanNum = parseInt(this.questionBank_info.accuracy);
        this.huanText =  `${this.questionBank_info.accuracy}` + '%';
      }else{
        this.huanNum = parseInt(this.questionBank_info.score);
        this.huanText =  `${this.questionBank_info.score}` + '分';
      }
      
    },

  }
};
</script>
  
<style lang="less" scoped>
.box {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/tiKuReport/bg-user.png") no-repeat;
  background-size: 100%;

  .boxWarp {
    width: 1200px;
    min-width: 1200px;

    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 90px;
    .oneBox {
      margin-top: 20px;
      width: 1200px;
     
      background: #FFFFFF;
      box-shadow: 0px 14px 44px 0px #EDF3F6;
      border-radius: 20px;
      .bottom_btn{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .chartsBox{
        padding-top:80px;
        margin:auto;
        width: calc(100% - 236px);
        height: 250px;
        .pieBox{
          width: 100%;
          height: 100%;
        }
      }
      .cardContent{
        width: calc(100% - 236px);
        margin-left: 118px;
        padding-top: 97px;
        .questionCardBox{
          width: 100%;
          text-align: left;
          .title{
            font-size: 18px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 500;
            color: #4C5158;
          }
          .content{
            display: flex;
            flex-wrap: wrap;
            div{
              margin-right: 21px;
              margin-top: 25px;

            }
            
          }
        }
        .card_Box_question{
          width: 18px;
          height: 18px;
          color:#ffffff;
          text-align: center;
          line-height: 18px;
          font-size: 13px;
        }
        .colorRight{
          background: #48BA83;
          border-radius: 4px;
          border: 1px solid #FFFFFF;

        }
        .colorError{
          background: #FF7975;
          border-radius: 4px;
          border: 1px solid #FFFFFF;

        }
        .colorWei{
          background: #F3F5F6;
          border-radius: 4px;
          border: 1px solid #E0E6ED;
          color: rgb(151, 156, 164)

        }
        .cardTop{
          width: 100%;
          display: flex;
          flex-direction: row;
          .childBox{
            display: flex;
            flex-direction: row;
            .card_text{
              margin-left: 5px;
              font-size: 16px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #676F7A;
            }
          }
        }
      }
      .item0 {

        background: url("../../assets/tiKuReport/s31_bg.png") no-repeat;
        background-size: 100% 100%;
        padding-bottom: 40px;
        position: relative;
        .finshBox{
                text-align: center;
                margin-top: 20px;
                width: 100%;
                height: 38px;
                font-size: 26px;
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 500;
                color: #28313D;
                line-height: 38px;
        }
        .question_situation{
          margin-top:26px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .bottomText{
            text-align: center;
            margin-top: 10px;
            font-size: 16px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 500;
            color: #4C5158;
          }
          .fontStyle{
            text-align: center;
            font-size: 24px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 500;
          }
          .fontColor{
            color: #0092FF
          }
          .errorColor{
            color: #FF3939;
          }
          .mar{
            margin-left:21px;
          }
          .lineBox{
            width: 2px;
            height: 27px;
            background:#E0E6ED;
          }
        }
        .crileBox{
            padding-top: 40px;
            width: 100%;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            
            .text{
                position: absolute;
                top:52%;
                font-size: 22px;
                color:#0092FF;
                font-weight: 600;
            }
            /deep/ .el-progress__text{
                color:#0092FF;
                font-size: 22px;
            }

        }
        .item_title {
            position: absolute;
            top:0px;
            left: 0px;
            width: 218px;
            height: 76px;
            background: url("../../assets/tiKuReport/s31_tbg.png") no-repeat;
            background-size: 100% 100%;
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            .leftIconStyle{
                margin-top: 15px;
            }
        }
        
       
      }
    }
    .tiKuTitle {
      text-align: center;
      height: 43px;
      font-size: 22px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #1d2c38;
      line-height: 43px;
    }
    .twoBox{
      margin-top: 20px;
      width: 100%;
      height: 147px;
      background: #FFFFFF;
      box-shadow: 0px 14px 44px 0px #EDF3F6;
      border-radius: 20px;
      border: 2px solid #FFFFFF;
      display: flex;
      flex-direction: row;
      .bottomTextBox{
          margin-top: 7px;
          font-size: 16px;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #4C5158;
        }
      .topbox{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        
        .da{
          font-size: 30px;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          color: #0092FF;
        }
        .xiao{
          font-size: 20px;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          color: #0092FF;
        }
      }
      div{
        width: 33.33333%;
        height: 147px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
  